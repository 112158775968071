'use strict';

$(document).ready(function () {
	var sliderContainer= '.slider-frenzy-product .slider';
	$(sliderContainer).each(function(){

		var sliderToDisplayInDesktop = $(this).closest('.slider-frenzy-product').attr('sliderToDisplayInDesktop');
		var sliderToDisplayInTablet = $(this).closest('.slider-frenzy-product').attr('sliderToDisplayInTablet');
		var sliderToDisplayInMobile = $(this).closest('.slider-frenzy-product').attr('sliderToDisplayInMobile');

		$(this).slick({
			infinite: true,
			slidesToShow: sliderToDisplayInDesktop,
			slidesToScroll: 1,
			arrows: true,
			responsive: [
			    {
			      breakpoint: 1024,
			      settings: {
			        slidesToShow: sliderToDisplayInTablet
			      }
			    },
			    {
			      breakpoint: 768,
			      settings: {
			        slidesToShow: sliderToDisplayInMobile
			      }
			    }
			]
		});
		$(this).removeClass('d-none');
	});

	$('.product-click-track').click(function(e){
		var data = $(e.currentTarget).data();
		var requestData = {};
		requestData.sku = data.id;
		requestData.event_name = data.eventname;
		requestData.query_id = data.queryid;
		requestData.user_id = data.userid;
		send_events(requestData);
	});

	async function send_events(requestData){
		let eventsUrl_Frenzy = "https://wgaca.search.frenzy.ai/events";
		var data_json = JSON.stringify([requestData]);
		const response = await fetch(eventsUrl_Frenzy, {
		method: 'POST',
		body: data_json,
		headers: {
			'Content-Type': 'application/json',
			'x-frenzy-authorization': 'b423c0ba-a5f5-4c32-b369-ad7bdc94a1b5'
		}
		});
		const data = await response.json();
	}
});